import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Breadcrumbs from "../components/Breadcrumbs"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import SubHero from "../components/SubHero"
import ImageCopy from "../components/ImageCopy"
import ThreeColumn from "../components/ThreeColumn"
import CallToAction from "../components/CTA"

const Location = ({ location }) => {
  const data = useStaticQuery(graphql`
    query LocationQuery {
      wpPage(databaseId: { eq: 5416 }) {
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphType
          canonical
        }
        featuredImage {
          node {
            link
          }
        }
        Location_CF {
          subHeroTitle
          subHeroSubTitle
          subHeroContent
          subHeroImage {
            sourceUrl
          }
          threeColumnHeaderText
          threeColumnContent
          threeColumnCards {
            content
            title
            link
            linkLabel
            image {
              sourceUrl
            }
          }
          headerText
          cardCollection {
            title
            image {
              sourceUrl
            }
            linkLabel
            linkUrl
            content
            buttonUrl
            buttonLabel
          }
          backgroundColor
          callToActionButtonLabel
          callToActionLink
          callToActionHeader
        }
      }
    }
  `)
  const locationData = data.wpPage.Location_CF
  const seoData = data.wpPage.seo
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Location",
  })
  return (
    <Layout>
      <Seo title={seoData.title} description={seoData.metaDesc} uri={seoData.canonical} />
      <Breadcrumbs crumbs={crumbs} />
      <SubHero hero={locationData} />
      <ThreeColumn columns={locationData.threeColumnCards} title={locationData.threeColumnHeaderText} content={locationData.threeColumnContent} background={locationData.backgroundColor} />
      <ImageCopy cards={locationData.cardCollection} headline={locationData.headerText} />
      <CallToAction cta={{ ctaHeader: locationData.callToActionHeader, ctaButtonLabel: locationData.callToActionButtonLabel, ctaLinkUrl: locationData.callToActionLink }} />
    </Layout>
  )
}

export default Location
